/**
  Removes special characters like ', " etc. from given param. Param should be already url decoded by the caller.

  @param {string} param the string to sanitize
  @returns {string} the sanitized string
*/
export const sanitizeString = (param: string): string => {
  if (typeof param !== 'string') {
    return '';
  }
  param = param.replace(/[^a-zA-Z0-9_\-\\.]/g, '');
  return encodeURIComponent(param);
};
